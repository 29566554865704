import { storeToRefs } from 'pinia';

export default function useProductLocalization() {
    const { currentProductCode } = storeToRefs(useProductStore());

    const i18n = useI18n();

    const { locale: globalLocale, locales: availableLocales, setLocale } = i18n;

    const availableCodes = computed(() => {
        return availableLocales.value
            .filter((item) => item.code.includes('-'))
            .map((item) => {
                const productCode = item.code.split('-').pop();

                return productCode;
            });
    });

    const isAvailable = (code) => {
        return !!availableCodes.value.includes(code);
    };

    const properties = computed(() => {
        const [base, specific, product] = globalLocale.value.split('-');

        return {
            base,
            specific,
            product,
        };
    });

    const getCodeForUpdate = (product, specific = null, base = null) => {
        if (!base) {
            base = properties.value.base;
        }

        if (!specific) {
            specific = properties.value.specific;
        }

        let newCode = base;

        // add specific code if available
        if (specific) {
            newCode = `${newCode}-${specific}`;
        }

        // add product code if available
        if (product) {
            newCode = `${newCode}-${product}`;
        }

        return newCode;
    };

    const updateLocale = (code) => {
        const { product } = properties.value;

        if (isAvailable(code) && product !== code) {
            setLocale(getCodeForUpdate(code));
            return;
        }

        setLocale(getCodeForUpdate());
    };

    watch(currentProductCode, (value) => {
        updateLocale(value);
    });

    return i18n;
}
